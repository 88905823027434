<script>
  import { mapActions, mapGetters, mapMutations } from 'vuex';
  import TYPE from '@/enums/type.js';
  import CurvedHeader from '@/components/general/curved-header/CurvedHeader.vue';
  import CourseGridCard from '@/components/courses/course-grid-card/CourseGridCard.vue';
  import CourseFiltersDialog from '@/components/courses/course-filters-dialog/course-filters-dialog/CourseFiltersDialog.vue';
  import courseFiltersMixin from '@/mixins/courseFiltersMixin.js';

  export default {
    mixins: [courseFiltersMixin],
    name: 'CoursesByTag',
    data() {
      return {
        isLoadingCourses: false,
        tagCourses: [],
        types: ['All', 'Exercises', 'Education'],
        tab: 0,
        type: 'all',
        exerciseCourses: [],
        educationCourses: [],
      };
    },
    components: {
      CurvedHeader,
      CourseGridCard,
      CourseFiltersDialog,
    },
    watch: {
      async courses() {
        await this.fetchCourses(false);
        this.setTabsCourses();
        this.filterTabsCourses(false);
      },
      tab() {
        this.mapTabToType();
      },
      type() {
        this.$nextTick(() => this.filterTabsCourses(false));
      },
    },
    computed: {
      ...mapGetters('CoursesModule', ['courses', 'getCoursesByTag']),
      tag() {
        return this.$route.params.tag;
      },
    },
    async created() {
      // Make `types` array a tuple.
      this.types = Object.freeze(this.types);
      await this.fetchCourses();
      this.setTabsCourses();
      this.filterTabsCourses(false);
    },
    methods: {
      ...mapMutations(['setErrorSnackbarMessage', 'setSuccessSnackbarMessage']),
      ...mapActions('CoursesModule', { fetchAllCourses: 'fetchAll' }),

      async fetchCourses(showLoading = true) {
        if (showLoading) {
          this.isLoadingCourses = true;
        }

        try {
          await this.fetchAllCourses();
          this.getThisTagCourses();
        } catch (error) {
          this.setErrorSnackbarMessage(error);
        } finally {
          this.isLoadingCourses = false;
        }
      },
      getThisTagCourses() {
        this.tagCourses = this.getCoursesByTag(this.tag);
      },
      setTabsCourses() {
        this.emptyTabs();
        this.tagCourses.forEach(course => {
          // If course type is `exercise`, we put it in the Exercises tab
          if (course.type === TYPE.EXERCISE) this.exerciseCourses.push(course);
          // If course type is `education`, we put it in the Education tab
          if (course.type === TYPE.EDUCATION) this.educationCourses.push(course);
        });
      },
      filterTabsCourses(showSnackbar = true) {
        switch (this.type) {
          case TYPE.EXERCISE:
            this.filterCourses(this.exerciseCourses, showSnackbar);
            break;
          case TYPE.EDUCATION:
            this.filterCourses(this.educationCourses, showSnackbar);
            break;
          case TYPE.ALL:
            this.filterCourses(this.tagCourses, showSnackbar);
            break;
        }
      },
      resetTabsCourses() {
        switch (this.type) {
          case TYPE.EXERCISE:
            this.resetCourses(this.exerciseCourses);
            break;
          case TYPE.EDUCATION:
            this.resetCourses(this.educationCourses);
            break;
          case TYPE.ALL:
            this.resetCourses(this.tagCourses);
            break;
        }
      },
      /**
       * Empty the tabs to avoid adding duplicates
       */
      emptyTabs() {
        this.exerciseCourses = [];
        this.educationCourses = [];
      },
      /**
       * Since vuetify uses a number to work with tabs, I created this helper function to map
       * types to each tab.
       */
      mapTabToType() {
        switch (this.tab) {
          case 0:
            this.type = TYPE.ALL;
            break;
          case 1:
            this.type = TYPE.EXERCISE;
            break;
          case 2:
            this.type = TYPE.EDUCATION;
            break;
        }
      },
    },
  };
</script>
