<template>
  <div
    class="course-card pa-2 v-clickable"
    @click="goToCourseDetailsRoute">
    <v-img
      class="course-card-cover"
      height="136px"
      :src="thumbnailUrl" />
    <div class="d-flex align-baseline justify-space-between">
      <h2 class="course-card-title pl-2 mt-4 mb-2">
        {{ course.title }}
      </h2>
      <v-btn
        class="toggle-favourite-button"
        small
        icon
        :loading="isToggleFavoriteLoading"
        :disabled="isToggleFavoriteLoading"
        @click.stop="toggleFavouriteStatus">
        <v-icon
          v-if="course.isFavorite"
          color="primaryColor"
          style="font-size: 16px">
          mdi-heart
        </v-icon>
        <v-icon
          v-else
          color="brandGrey2"
          style="font-size: 16px">
          mdi-heart-outline
        </v-icon>
      </v-btn>
    </div>

    <div class="course-card-details d-flex align-center pl-2">
      <div class="mr-2">{{ course.category?.name }}</div>
      <v-icon x-small>mdi-circle-medium</v-icon>
      <div class="image-container mx-2">
        <v-img :src="course.audioUrl ? require('@/assets/course/audio.svg') : require('@/assets/course/video.svg')">
        </v-img>
      </div>
      {{ course.duration }} min
    </div>
    <div
      class="px-2"
      v-if="course.progress">
      <div style="background-color: rgba(51, 51, 51, 0.08); height: 3px; border-radius: 9999px; margin: 10.5px 0">
        <v-progress-linear
          color="primaryColor"
          background-color="#33333314"
          height="3px"
          :value="course.progress"></v-progress-linear>
      </div>
    </div>
  </div>
</template>

<script>
  import CourseRowCardMixin from '@/components/courses/course-row-card/CourseRowCard.mixin.vue';
  export default {
    mixins: [CourseRowCardMixin],
  };
</script>

<style>
  .v-progress-linear__determinate {
    height: 3px;
    border-radius: 9999px;
  }
</style>

<style lang="css" scoped>
  .course-card {
    background: var(--v-offWhite-base);
    border-radius: 24px;
  }
  .v-clickable {
    cursor: pointer;
  }
  .course-card-title {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color: var(--v-offBlack-base);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .course-card-details {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: var(--v-brandGrey2-base);
  }
  .course-card-cover {
    border-radius: 24px;
  }

  .image-container {
    width: 14px;
    height: 14px;
  }
  .image-container > img {
    width: 100%;
    height: 100%;
  }
</style>
