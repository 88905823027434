/**
 * @typedef {'all' | 'exercise' | 'education'} Type
 */

/**
 * Enum for course type values.
 * @readonly
 * @enum {Type}
 */
const TYPE = Object.freeze({
  EXERCISE: 'exercise',
  EDUCATION: 'education',
  ALL: 'all',
});

export default TYPE;
